<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
      <b-dropdown
        variant="link"
        no-caret
        toggle-class="p-0"
        right
      >

        <template #button-content>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            pill
          >
            <feather-icon icon="SettingsIcon" />
          </b-button>
        </template>

        <b-dropdown-item :to="{ name: 'espace-admin.activateaccount' }">
          <feather-icon
            icon="CheckSquareIcon"
            size="16"
          />
          <span class="align-middle ml-50">Activation de compte</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'espace-admin.devise' }">
          <feather-icon
            icon="DollarSignIcon"
            size="16"
          />
          <span class="align-middle ml-50">Devise</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'espace-admin.document' }">
          <feather-icon
            icon="FileTextIcon"
            size="16"
          />
          <span class="align-middle ml-50">Documents</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'espace-admin.pays' }">
          <feather-icon
            icon="GlobeIcon"
            size="16"
          />
          <span class="align-middle ml-50">Données géographiques</span>
        </b-dropdown-item>
        <b-dropdown-item :to="{ name: 'espace-admin.comment' }">
          <feather-icon
            icon="MessageCircleIcon"
            size="16"
          />
          <span class="align-middle ml-50">Commentaire</span>
        </b-dropdown-item>
        <b-dropdown-item :to="{ name: 'espace-admin.unittype' }">
          <feather-icon
            icon="AnchorIcon"
            size="16"
          />
          <span class="align-middle ml-50">Unité de mesure</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'espace-admin.transport' }">
          <feather-icon
            icon="TruckIcon"
            size="16"
          />
          <span class="align-middle ml-50">Modèle de camions</span>
        </b-dropdown-item>
        <b-dropdown-item :to="{ name: 'espace-admin.marchandise' }">
          <feather-icon
            icon="ShoppingBagIcon"
            size="16"
          />
          <span class="align-middle ml-50">Marchandise</span>
        </b-dropdown-item>
        <b-dropdown-item :to="{ name: 'espace-admin.statistiques' }">
          <feather-icon
            icon="TrendingUpIcon"
            size="16"
          />
          <span class="align-middle ml-50">Statistique</span>
        </b-dropdown-item>
        <b-dropdown-item :to="{ name: 'espace-admin.manage.user' }">
          <feather-icon
            icon="UsersIcon"
            size="16"
          />
          <span class="align-middle ml-50">Gestion des utilisateurs</span>
        </b-dropdown-item>
        <b-dropdown-item :to="{ name: 'espace-admin.offers' }">
          <feather-icon
            icon="BriefcaseIcon"
            size="16"
          />
          <span class="align-middle ml-50">Validations des offres</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
}
</script>
